
import { INVALID_MOVE } from 'boardgame.io/core';
import { effects, skills } from './effects';

var cards = require('./cards.json')
var heroes = require('./heroes.json')
var levels = require('./levels.json')
var bosses = require('./bosses.json')


const dietypes = {
  strength : 8,
  agility  : 8,
  magic    : 8,
  heroic   : 6,
  poison   : 1,
}

export const OneDeckDungeon = {
  setup: setup,
  playerView: playerState,
  phases: {
    init: {
      start: true,
      onBegin: startFloor,
      endIf: ({G}) => !G.discard.length,
      next: 'choice',
    },
    choice: {
      onBegin: startTurn,
      moves: {
        explore: explore,
        enter: enter,
        descend: descend,
      },
      next: 'open',
    },
    resist: {
      onBegin: rollPoison,
      moves: {
        resist: resist,
      },
      endIf: ({G}) => !G.poison,
      next: 'choice',
    },
    open: {
      moves: {
        encounter: encounter,
        flee: flee,
      },
      next: 'heroic',
    },
    heroic: {
      onBegin: skipHeroic,
      moves: {
        heroic1: heroic1,
        heroic2: heroic2,
      },
      next: 'roll',
    },
    roll: {
      onBegin: rollAll,
      next: 'place',
    },
    skill: {
      onBegin: unsetskill,
      moves: {
        toggledie: toggledie,
        useskill: useskill,
      },
      next: 'place',
    },
    place: {
      moves: {
        toggledie: toggledie,
        cover: cover,
        clearbox: clearbox,
        useskill: useskill,
        getheroic: getheroic,
        discarddice: discarddice,
        endfight: endfight,
      },
      next: 'loot',
    },
    loot: {
      moves: {
        items: items,
        xp: xp,
        skill: skill,
      },
      next: 'choice',
    },
    discard: {
      moves: {
        discard: discard,
      },
      next: 'choice',
    },
  },
}

function setup ({ ctx, ...plugins }, setupData) {
  var deck;
  deck = Object.values(cards).filter($ => $.expo === setupData?.deck)
  deck = deck.length ? deck : Object.values(cards).filter($1 => !!$1.set)

  return {
    boss: bosses[setupData?.boss] || bosses['Dragon'],
    hero: heroes[setupData?.hero] || heroes['Mage'],
    items: [],
    skills: [],
    potions: [],
    xps: [],
    ptokens: 1,
    stairs: 0,
    hp: 0,
    poison: 0,

    effect: null,
    dice: (()=>{const results=[];for (const dtype in dietypes) { const cnt = dietypes[dtype];results.push( (()=>{const results1=[];for (let i1 = 0, asc = 0 <= cnt; asc ? i1 < cnt : i1 > cnt; asc ? ++i1 : --i1) { results1.push( {
      dtype: dtype,
      value: 1,
      loc: 'supply',
      locopt: null
    }) }return results1})()) }return results})().flat(1).map((x, i) => ({idx: i ,...x})),

    level: levels[0],
    floor: 0,
    deck: [],
    dealt: 0,
    dungeon: [null, null, null, null],
    doors: [null, null, null, null],
    discard: deck,
    room: null,
  }
}

function playerState({ G, ctx, playerID }) {
  var deck, dungeon, rg;
  ({deck, dungeon, ...rg} = G)
  rg.deck = deck.length
  rg.dungeon = G.doors.map((e, i) => e ? dungeon[i] : e)
  return rg
}

// ****************************************
//   Phase: HELPER FUNCTIONS
// ****************************************

export function tick(G) {
  if (G.deck.length > 0) {
    G.discard.push(G.deck.pop())
  }
  else {
    G.stairs += 1
  }

  while (G.stairs > 2) {
    G.stairs -= 3
    G.hp += 1
  }

  return !!G.deck.length
}

function curcard(G) {
  return G.dungeon[G.room]
}

export function rollOne(G, random, dtype, opt=false, from='supply', to='pool') {
  for (const d of G.dice) {
    if (d.dtype === dtype && d.loc === from) {
      d.loc = to
      d.locopt = opt
      d.value = random.D6()
      d.animated = true
      return d
    }
  }
  return false
}

export function gain(G, dtype, val, opt=false, from='supply', to='pool') {
  var d;
  d = rollOne(G, { D6: () => val }, dtype, opt, from, to)
  d.animated = false
  return d
}

// ****************************************
//   Phase: GAME FUNCTIONS
// ****************************************

function startFloor({ G, random }) {
  G.dealt = 0
  G.stairs = 0
  G.floor += 1
  G.deck = random.Shuffle(G.discard)
  G.dungeon = [null, null, null, null]
  G.doors = [null, null, null, null]
  G.discard = []
}

function startTurn({ G, playerID, events }) {
  G.room = null
  G.effect = null
  G.skill = null
  for (const d of G.dice) {
    d.loc = 'supply'
    d.locopt = null
  }
  G.hero.skill.used = false
  for (const c of G.skills) {
    c.skill.used = false
  }
  G.prevent = {
    time: 0,
    health: 0,
    poison: 0,
  }
}

function endTurn(events) {
    events.setPhase('choice')
}

// ****************************************
//   Phase: CHOICE
// ****************************************

// ** Helper functions

function timepass (G, events) {
  if (!G.deck.length) {
    if (G.dealt <= 1) {
      tick(G)
    }
    if (G.dealt <= 0) {
      tick(G)
    }
    G.dealt = 0
    return
  }

  G.dealt = 0
  for (let i2 = 0; i2 < 2; ++i2) {
    tick(G)
    G.dealt += 1
    if (!G.deck.length) {
      events.setPhase('choice')
      return true
    }
  }
}

// ** Moves

function explore({ G, playerID, events }) {
  if (timepass(G, events)) {
    return
  }

  if (G.dungeon.every(Boolean)) {
    return INVALID_MOVE
  }
  if (!G.deck.length) {
    return INVALID_MOVE
  }
  for (let i3 = 0; i3 < 4; ++i3) {
    const i = i3;
    if (!G.dungeon[i]) {
      G.dungeon[i] = G.deck.pop()
      G.doors[i] = G.dungeon[i] ? false : null
      if (!G.deck.length) {
        break
      }
    }
  }
  events.setPhase('resist')
}

function enter({ G, playerID, events }, room) {
  if (timepass(G, events)) {
    return
  }

  if (G.dungeon[room] == null) {
    return INVALID_MOVE
  }
  G.doors[room] = true
  G.room = room
  events.endPhase()
}

function descend({ G, playerID, events }, room) {
  if (!!G.deck.length) {
    return INVALID_MOVE
  }

  for (let i4 = 0; i4 < 4; ++i4) {
    const i = i4;
    if (!!G.dungeon[i]) {
      G.discard.push(G.dungeon[i])
    }
  }

  events.setPhase('init')
}

// ****************************************
//   Phase: RESIST
// ****************************************

function rollPoison({ G, playerID, random }) {
  rollOne(G, random, 'poison', true)
}

export function checkResist( G ) {
  var dval;
  dval = selecteddice(G)[0].value
  for (let i5 = 0; i5 < 4; ++i5) {
    const i = i5;
    dval += (G.doors[i] && G.dungeon[i].set === '🌿') ? 1 : 0
  }
  return dval > G.poison
}

function resist({ G, playerID, events }) {
  if (!checkResist(G)) {
    G.hp += 2
  }
  G.poison--
  events.endPhase()
}

// ****************************************
//   Phase: OPEN
// ****************************************

function flee({ events }) {
  endTurn(events)
}

function encounter({ G, playerID, events, random }, eff) {
  var ce;
  G.effect = eff
  const c = curcard(G)
  ce = c.effects[eff]
  for (let end = ce.timecost, i6 = 0, asc1 = 0 <= end; asc1 ? i6 < end : i6 > end; asc1 ? ++i6 : --i6) {
    tick(G)
  }
  if (!effects[ce.name]?.fn?.({ G, playerID, events, random })) {
    events.endPhase()
  }
}

// ****************************************
//   Phase: HEROIC
// ****************************************

// ** Helper functions

function canHeroic({ G, playerID }) {
  return [false, false]
}

function skipHeroic({G, playerID, events}) {
  if (!canHeroic({G, playerID}).some($2 => $2)) {
    events.endPhase()
  }
}

// ** Moves

function heroic1({ G, playerID, events }) {
    events.endPhase()
}

function heroic2({ G, playerID, events }) {
    events.endPhase()
}

// ****************************************
//   Phase: ROLL
// ****************************************

// ** Helper functions

function rollAll({ G, playerID, random, events }) {
  var ce, cnt;
  const c = curcard(G)
  for (const dtype in dietypes) {
    if (c.combat || (c.peril && (dtype === c.effects[G.effect].boxes[0].dtype || dtype === 'heroic'))) {
      cnt = (G.hero.itms[dtype] || 0 ) + (G.level[dtype] || 0)
      for (const i of G.items) {
        cnt += (i.itms[dtype] || 0)
      }
      for (let i7 = 0, asc2 = 0 <= cnt; asc2 ? i7 < cnt : i7 > cnt; asc2 ? ++i7 : --i7) {
        rollOne(G, random, dtype)
      }
    }
  }

  ce = c.effects[G.effect]
  if (!effects[ce.name]?.postroll?.({ G, playerID, events, random })) {
    events.endPhase()
  }
}

function filterdice(G, filt) {
    return G.dice.filter((d) => {
        for (const k in filt) {
            const v = filt[k];
            if (d[k] !== v) {
                return false
            }
        }
        return true
    })
}

export function pooldice(G) {
    return filterdice(G, { loc: 'pool' })
}

function selecteddice(G) {
    return filterdice(G, { loc: 'pool', locopt: true })
}

export function taggeddice(G) {
    return filterdice(G, { loc: 'pool', locopt: Math.sign(G.maxdice) })
}

export function discardtagged( G ) {
    for (const d of taggeddice(G)) {
        d.loc = 'supply'
        d.locopt = null
    }
}

function getbox(G, box) {
    return {
      dice: filterdice(G, {loc: 'box', locopt: box}),
      ...curcard(G)?.effects[G.effect]?.boxes[box]
    }
}

export function curboxes(G) {
    return curcard(G)?.effects[G.effect]?.boxes?.map((x, i) => getbox(G, i))
}

export function emptyboxes(G) {
    return curboxes(G)?.filter($3 => !$3.dice.length)
}

export function deselectdice( G ) {
    for (const d of selecteddice(G)) {
      if (d.loc === 'pool') {
        d.locopt = false
      }
    }
}

function getskill(G, i, potion) {
    if (potion) {
      return G.potions[G.potions.length - i].skill
    }
    if (i === 0) {
        return G.hero.skill
    }
    return G.skills[G.skills.length - i].skill
}

// ** Helper functions: Allowable moves

export function cancover( G, box ) {
    var b, dice;
    b = getbox(G, box)
    dice = selecteddice(G)
    let hp = 0

    if (!b) {
        return false
    }
    if (b.dice.length) {
        return false
    }
    if (b.one && dice.length !== 1) {
        return false
    }
    for (const d of dice) {
        if (d.dtype !== b.dtype && d.dtype !== 'heroic') {
            return false
        }
        hp += d.value
    }
    if (hp < b.hp) {
        return false
    }
    return true
}

function unsetskill( {G} ) {
    getskill(G, ...G.skill).used = false
}

export function canuseskill( G, phase, i, potion ) {
    var d, dtype, fn;
    if (phase === 'skill') {
        if (JSON.stringify(G.skill) !== JSON.stringify([i, potion])) {
            return false
        }
        d = taggeddice(G)
        if (d.length < Math.abs(G.mindice)) {
            return false
        }
        if (d.length > Math.abs(G.maxdice)) {
            return false
        }
        return true
    }

    if (phase !== 'place') {
        return false
    }

    const c = curcard(G)
    const skill = getskill(G, i, potion)
    const dice = selecteddice(G)

    if (skill.used) {
        return false
    }
    if (c.peril && !skill.peril) {
        return false
    }
    if (c.combat && !skill.combat) {
        return false
    }
    if (skill.type === '⚗️' && !G.ptokens) {
        return false
    }

    switch(skill.type) {
      case "🌀": {
        dtype = 'magic'
        fn = (d) => d.value;break;
      }
      case "👢": {
        dtype = 'agility'
        fn = () => 1;break;
      }
      case "🗡️": {
        dtype = 'strength'
        fn = () => 1;break;
      }
      default: {
        // when 🔄 or ⚗️
        return dice.length === 0
      }
    }

    let tot = 0
    for (const d of dice) {
        if (d.dtype !== dtype && d.dtype !== 'heroic') {
            return false
        }
        tot += fn(d)
    }
    return (skill.cost <= tot && tot)
}

// ** Moves

function toggledie({ G, ctx, playerID, events }, ind) {
    var d;
    if (ctx.phase === 'place') {
      if (G.dice[ind].loc === 'pool') {
        G.dice[ind].locopt = !G.dice[ind].locopt
      }
      return
    }

    d = G.dice[ind]
    if (d.loc !== 'pool' || d.locopt === false) {
        return
    }
    if (d.locopt !== true) {
        d.locopt = true
    }
    else {
        d.locopt = Math.sign(G.maxdice)
    }
}


function clearbox({ G, playerID, events }, box) {
    var b;
    b = getbox(G, box)
    if (!b.dice.length) {
        return
    }

    deselectdice(G)
    for (const d of b.dice) {
      d.loc = 'pool'
      d.locopt = true
    }
}

function cover({ G, playerID, events }, box) {
    if (!cancover(G, box)) {
      return INVALID_MOVE
    }

    for (const d of selecteddice(G)) {
      d.loc = 'box'
      d.locopt = box
    }
}

function useskill({ G, ctx, playerID, random, events }, i, potion) {
    var cost, skill;
    cost = canuseskill(G, ctx.phase, i, potion)
    if (!cost) {
      return INVALID_MOVE
    }

    G.skill = [i, potion]
    skill = getskill(G, i, potion)

    if (ctx.phase === 'skill') {
      skills[skill.name].then(G, random)
      skill.used = true
      for (const d of taggeddice(G)) {
        d.locopt = false
      }
      deselectdice(G)
      events.endPhase()
      return
    }

    if (potion) {
        G.ptokens -= 1
    }
    else {
        discarddice(G)
        skill.used = true
    }

    skills[skill.name].fn({G, playerID, random, events}, cost)
}

export function cangetheroic( G ) {
    return (selecteddice(G)).length === 2
}

function getheroic({ G, playerID, events }) {
    var dice, val, d;
    if (!cangetheroic(G)) {
      return INVALID_MOVE
    }

    dice = selecteddice(G)
    val = Math.min(...dice.map($4 => $4.value))
    d = gain(G, 'heroic', val)
    if (!!d) {
      discarddice(G)
      d.locopt = true
    }
}

export function discarddice( G ) {
    for (const d of selecteddice(G)) {
        d.loc = 'supply'
        d.locopt = null
    }
}

function endfight({ G, playerID, events }) {
    var boxes, empties;
    boxes = curboxes(G)
    empties = emptyboxes(G)

    if (empties.some($5 => $5.armor) && empties.length < boxes.length) {
        for (let end1 = boxes.length, i8 = 0, asc3 = 0 <= end1; asc3 ? i8 < end1 : i8 > end1; asc3 ? ++i8 : --i8) {
            const i = i8;
            clearbox({G}, i)
        }
        deselectdice(G)
        return
    }

    const fns = {
        time: () => tick(G),
        health: () => G.hp++,
        poison: () => G.poison++,
    }

    for (const b of empties) {
      for (const con in fns) {
        const fn = fns[con];
        for (let end2 = b.cons[con], i9 = 0, asc4 = 0 <= end2; asc4 ? i9 < end2 : i9 > end2; asc4 ? ++i9 : --i9) {
          if (G.prevent[con]) {
              G.prevent[con]--
          }
          else {
              fn()
          }
        }
      }
    }

    events.endPhase()
}


// ****************************************
//   Phase: LOOT
// ****************************************

// ** Helper functions

function endloot({ G, playerID, events }) {
  G.dungeon[G.room] = null
  G.doors[G.room] = null
  checklimits({ G, playerID, events })
}

function checklimits({ G, playerID, events }) {
  for (const res of ["items", "skills"]) {
    if (G[res].length > G.level[res]) {
      events.setPhase('discard')
      return
    }
  }
  events.endPhase()
}

// ** Moves

function items({ G, playerID, events }) {
  G.items.push(curcard(G))
  endloot({G, playerID, events})
}

function xp({ G, playerID, events }) {
  G.xps.push(curcard(G))
  const xps = G.xps.map($6 => $6.xp).reduce((x,y) => x+y, 0)
  if (xps >= G.level.levelup) {
    G.level = levels[G.level.next]
    G.xps = []
    G.ptokens += G.level.ptokens
  }
  endloot({G, playerID, events})
}

function skill({ G, playerID, events }) {
  if (curcard(G).skill.type === "⚗️") {
    G.potions.push(curcard(G))
    G.ptokens++
  }
  else {
    G.skills.push(curcard(G))
  }
  endloot({G, playerID, events})
}

// ****************************************
//   Phase: DISCARD
// ****************************************

function discard({ G, playerID, events }, res, i) {
  G[res].splice(G[res].length - i, 1)
  checklimits({ G, playerID, events })
}
