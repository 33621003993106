import React from 'react'

export default function Skill( {skill, "class": className, useskill, ...props} ) {
  return <div className={["carddata skill", useskill && "activemove", skill.used && "inactive", className].filter(Boolean).join(" ")}
        {...( useskill && {onClick: useskill} )}
        {...props}>
    <div class={["carddata skilltype", skill.type].filter(Boolean).join(" ")}> {skill.type}
    </div>
    <div class={["carddata skillcost", skill.type].filter(Boolean).join(" ")}> {skill.cost ? skill.cost : (skill.type === '🌀' ? 'X' : '')}
    </div>
    <div class="carddata skilldesc" dangerouslySetInnerHTML={{__html: skill.details}}  />
    <div class="carddata skillname"> {skill.name}
    </div>
    <div class="carddata skilluse">
      <div class={["use noboss", !skill.boss].filter(Boolean).join(" ")} />
      <div class={["use peril", skill.peril].filter(Boolean).join(" ")} />
      <div class={["use combat", skill.combat].filter(Boolean).join(" ")} />
    </div>
  </div>
}
