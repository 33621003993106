import React from 'react'
import Items from './Items'
import Effect from './Effect'
import Skill from './Skill'

export default function Card( {
        "card": c,
        hp,
        size, label,
        effect,
        encounter, rolling, looting,
        cancover, dice,
        useskill,
        warnitems, warnskills, warnend, critend,
        moves,
        "class": className, ...props} ) {
  const front = c && typeof(c) == "object"

  return <div className={["card", size ? "full" : "empty", front ? "front" : "back", c?.expo, c?.combat && 'combat', c?.peril && 'peril', className].filter(Boolean).join(" ")} {...props}>
    <div class="label"> {label}
    </div>
    <div class="label size"> {size}
    </div>
    {(front?(
      <>
      <div class="carddata encounter" />
      <div class="carddata name"> {c.name}
      </div>
      <div class="carddata set"> {c.set}
      </div>
      <div class="carddata xps" {...( looting && {className: "carddata xps activemove", onClick: () => moves.xp()} )} >
        {(()=>{const results=[];for (let end = c.xp, i = 0, asc = 0 <= end; asc ? i < end : i > end; asc ? ++i : --i) {
          const key = i;
          results.push(<div class="xp" key={key} />)
        }return results})()
        }
      </div>

      <Items itms={c.itms} hp={hp} {...( (()=>{let m;if(m = [looting, !!c?.effects?.[effect]?.noitem],Array.isArray(m) && m.length === 2 && m[0] === true && m[1] === false) {
          const [,] = m;
          return ({class: "activemove" + (warnitems ? " warnmove" : ''),
          onClick: () => moves.items()})}
else if(Array.isArray(m) && m.length === 2 && m[0] === true && m[1] === true) {
          const [,] = m;
          return ({class: "inactive"})}
else  {
            return ({})
        }})()
      )}  />

      <> {(encounter?(
        <div class="carddata cardimage activemove" onClick={() => moves.flee()} > 🏃💨🚪
        </div>
      ):void 0)
      }
      </>
      <> {(rolling?(
        <div class={["carddata cardimage activemove", warnend && 'warnmove', critend && 'critmove'].filter(Boolean).join(" ")} onClick={() => moves.endfight()} > 🏁🏁⚔️
        </div>
      ):void 0)
      }
      </>
      <div class={["carddata effects", looting && 'inactive'].filter(Boolean).join(" ")}>
        {(()=>{const results1=[];let i1 = 0;for (const e of c.effects) {
          const key = i1++;
          results1.push(<Effect e={e} key={key} cancover={cancover} moves={moves}
            {... ((()=>{let m1;if(m1 = [(effect != null), effect === key, !!encounter],Array.isArray(m1) && m1.length === 3 && m1[2] === true) {
                const [ , ,] = m1;
                return ({class: "activemove",
                onClick: () => moves.encounter(key)})}
else if(Array.isArray(m1) && m1.length === 3 && m1[0] === true && m1[1] === false && m1[2] === false) {
                const [,,] = m1;
                return ({class: "inactive"})}
else if(Array.isArray(m1) && m1.length === 3 && m1[0] === true && m1[1] === true && m1[2] === false) {
                const [,,] = m1;
                return ({rolling: rolling,
                dice: dice})}
else  {
                return ({})
              }})()
            )}
           />)
        }return results1})()
        }
      </div>
      <Skill skill={c.skill} useskill={useskill} {...( (()=>{let m2;if(m2 = [looting, !!c?.effects?.[effect]?.noskill],Array.isArray(m2) && m2.length === 2 && m2[0] === true && m2[1] === false) {
          const [,] = m2;
          return ({class: "activemove" + (warnskills ? " warnmove" : ''),
          onClick: () => moves.skill()})}
else if(Array.isArray(m2) && m2.length === 2 && m2[0] === true && m2[1] === true) {
          const [,] = m2;
          return ({class: "inactive"})}
else  {
            return ({})
        }})()
      )}  />
      </>
    ):void 0)
    }
    {props.children}
  </div>
}
