import React from 'react'
import Hero from './Hero'
var heros = require('../heroes.json')

export default function Heroz() {
  return <div class="allheroz">
    {(()=>{const results=[];for (const key in heros) {
      const hero = heros[key];
      results.push(<div class="herozwrap">
        <Hero class="heroz" hero={hero} key={key} />
      </div>)
    }return results})()
    }
  </div>
}
