import React from 'react'
import Items from './Items'
import Skill from './Skill'
import Herocards from './Herocards'

export default function Hero( {"hero": h, level, items, canuseskill, skills, hp, poison, moves, discard, "class": className, ...props} ) {
  return <>
    <div className={["hero", h?.expo, className].filter(Boolean).join(" ")} {...props}>
      <div class="carddata name"> {h.name}
      </div>
      <Items itms={h.itms} hp={hp} poison={poison} />
      <Skill skill={h.skill} useskill={canuseskill?.(0) && (() => moves.useskill(0))} />
      <Skill class="heroic" skill={h.heroic} />
    </div>
    <div class="herocards heroitems">
      <Herocards max={level?.items} cards={items} moves={moves} hp={hp - h.itms.health} discard={discard === "items" && discard}  />
    </div>
    <div class="herocards heroskills">
      <Herocards max={level?.skills + 1} cards={skills} moves={moves} canuseskill={canuseskill} discard={discard === "skills" && discard}  />
    </div>
  </>
}
