import React from 'react'

function ItemArray( {itemtype, count, used} ) {
  return <div class={["item", itemtype, "c"+count].filter(Boolean).join(" ")}>
    { (()=>{const results=[];for (let i = count, asc = count <= 0; asc ? i < 0 : i > 0; asc ? ++i : --i) {
      const key = i;
      results.push(<div class={["itemicon", itemtype + "icon", used >= key && "used"].filter(Boolean).join(" ")} key={key} />)
    }return results})()
    }
  </div>
}

export default function Items( {itms, hp, poison, "class": className, ...props} ) {
  return <div className={["carddata items", className].filter(Boolean).join(" ")} {...props}>
    <ItemArray itemtype="itemy" count={itms.strength} />
    <ItemArray itemtype="itemp" count={itms.agility} />
    <ItemArray itemtype="itemb" count={itms.magic} />
    <ItemArray itemtype="itemh" count={itms.health} used={hp} />
    { poison > 0 &&
      <ItemArray itemtype="itemd" count={poison} />
    }
  </div>
}
