import React from 'react'
import Card from './Card'
var cards = require('../cards.json')

export default function Cardz() {
  return <div class="allcardz">
    {(()=>{const results=[];for (const key in cards) {
      const card = cards[key];
      results.push(<div class="cardzwrap">
        <Card class="cardz" size={1} card={card} key={key} />
      </div>)
    }return results})()
    }
  </div>
}
