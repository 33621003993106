import React from 'react'
import Die from './Die'

export default function Effect( {e, rolling, cancover, moves, dice, "class": className, ...props} ) {
  cancover ??= () => false
  function Boxes({bs}) {
    return <>
      {(()=>{const results=[];let i = 0;for (const b of bs || []) {
        const key = i++;
        results.push(<div class={["box", b.res, b.one ? "small" : "big", (rolling && cancover(key)) && "activemove"].filter(Boolean).join(" ")}
            key={key}
            {...( (()=>{let m;if(m = [cancover(key), !!(dice?.filter($ => $.locopt === key))?.length, rolling],Array.isArray(m) && m.length === 3 && m[0] === true && m[2] === true) {
                const [, ,] = m;
                return ({onClick: () => moves?.cover(key)})}
else if(Array.isArray(m) && m.length === 3 && m[1] === true && m[2] === true) {
                const [,,] = m;
                return ({onClick: () => moves?.clearbox(key)})}
else  {
                return ({})
              }})()
            )}
        >
          {(()=>{const results1=[];let i1 = 0;for (const d of (dice?.filter($1 => $1.locopt === key)) || []) {
            const di = i1++;
            results1.push(<Die class={(d.dtype) || ""} key={di} face={d.value} sel={false} animated={false} />)
          }return results1})()
          }
          <div class="hp"> {b.hp || "X" }
          </div>
          <div class="cons">
            {(()=>{const results2=[];let i2 = 0;for (const s of b.consstr) {
                const key = i2++;
                results2.push(<div class={["con", s].filter(Boolean).join(" ")} key={key} />)
            }return results2})()
            }
          </div>
        </div>)
      }return results})()
      }
    </>
  }

  return <div className={["ebox", className].filter(Boolean).join(" ")} {...props}>
    <div class="boxes">
      <Boxes bs={e.preboxes} />
    </div>
    <div class={["effect", e.name === "None"].filter(Boolean).join(" ")} key={e.name}>
      <div class="ename"> {e.name}
        {(()=>{const results3=[];for (const key in ((s, e) => {let step = e > s ? 1 : -1; return Array.from({length: Math.abs(e - s)}, (_, i) => s + i * step)})(0,e.timecost)) {
          results3.push(<div class="etimecost" key={key} />)
        }return results3})()
        }
      </div>
      <div class="edesc"> {e.details}
      </div>
    </div>
    <div class="boxes">
      <Boxes bs={e.boxes} />
      <div class={["arrow", e.noskill && "left", e.noitem && "down"].filter(Boolean).join(" ")} />
    </div>
  </div>
}
