import React from 'react'
import Effect from './Effect'

export default function Dungeon( {
        "card": c,
        effect,
        encounter, rolling, looting,
        cancover, dice,
        moves,
        "class": className, ...props} ) {

  return <div className={["card dungeon front full", c?.expo, className].filter(Boolean).join(" ")} {...props}>
    <div class="carddata encounter peril"> ALL 🪤
    </div>
    <div class="carddata encounter combat"> ALL ⚔️
    </div>
    <div class="carddata name"> {c.dungeon}
    </div>
    <div class="carddata set"> {c.set}
    </div>

    <div class={["carddata effects", looting && 'inactive'].filter(Boolean).join(" ")}>
      {(()=>{const results=[];let i = 0;for (const e of c.floors) {
        const key = i++;
        results.push(<Effect e={e} key={key} cancover={cancover} moves={moves}
          {... ((()=>{let m;if(m = [(effect != null), effect === key, !!encounter],Array.isArray(m) && m.length === 3 && m[2] === true) {
              const [ , ,] = m;
              return ({class: "activemove",
              onClick: () => moves.encounter(key)})}
else if(Array.isArray(m) && m.length === 3 && m[0] === true && m[1] === false && m[2] === false) {
              const [,,] = m;
              return ({class: "inactive"})}
else if(Array.isArray(m) && m.length === 3 && m[0] === true && m[1] === true && m[2] === false) {
              const [,,] = m;
              return ({rolling: rolling,
              dice: dice})}
else  {
              return ({})
            }})()
          )}
         />)
      }return results})()
      }
    </div>

    {props.children}
  </div>
}
