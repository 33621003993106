import React from 'react'
import Card from './Card'

export default function Herocards( {cards, max, discard, hp, moves, canuseskill, potion, "class": className, ...props} ) {
  if (max > 0 || cards?.length) {
    let [thiscard, ...restcards] = cards

    return <div className={["herocard", className].filter(Boolean).join(" ")} {...props} >
      <Herocards cards={restcards} max={max-1} discard={discard} moves={moves} canuseskill={canuseskill} potion={potion} hp={hp - thiscard?.itms?.health} class={className} {...props}  />
      <Card size={!!thiscard} card={thiscard} hp={hp}
        useskill={canuseskill?.(cards.length) && (() => moves.useskill(cards.length, potion))}
        {...((thiscard && discard) &&
          {class: "activemove critmove",
          onClick: () => moves.discard(discard, cards.length)}
        )}
       />
    </div>
  };return
}
