import { Client } from 'boardgame.io/react'
import { OneDeckDungeon } from './Game'
import { setupData } from './Config'
import Board from './components/Board'
import './App.css'

function setupGame (game) {
  return (setupData) => ({
    ...game,
    setup: (ctx) => game.setup(ctx, setupData)
  })
}

const setupODD = setupGame(OneDeckDungeon)

const App = Client({
    game: setupODD(setupData),
    board: Board,
    numPlayers: 1,
})

export default App
