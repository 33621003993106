import React from 'react';
import './Die.css';

const pips = {
  topLeft: <circle r="7" cx="25" cy="25" />,
  topRight: <circle r="7" cx="75" cy="25" />,
  centre: <circle r="7" cx="50" cy="50" />,
  bottomLeft: <circle r="7" cx="25" cy="75" />,
  bottomRight: <circle r="7" cx="75" cy="75" />,
}

const PipFaces = [
  <svg class="jk-pip-face" viewBox="0 0 100 100">
    {pips.centre}
  </svg>,
  <svg class="jk-pip-face" viewBox="0 0 100 100">
    {pips.bottomLeft}
    {pips.topRight}
  </svg>,
  <svg class="jk-pip-face" viewBox="0 0 100 100">
    {pips.bottomLeft}
    {pips.centre}
    {pips.topRight}
  </svg>,
  <svg class="jk-pip-face" viewBox="0 0 100 100">
    {pips.topLeft}
    {pips.topRight}
    {pips.bottomLeft}
    {pips.bottomRight}
  </svg>,
  <svg class="jk-pip-face" viewBox="0 0 100 100">
    {pips.topLeft}
    {pips.topRight}
    {pips.centre}
    {pips.bottomLeft}
    {pips.bottomRight}
  </svg>,
  <svg class="jk-pip-face" viewBox="0 0 100 100">
    {pips.topLeft}
    {pips.topRight}
    <circle r="7" cx="25" cy="50" />
    <circle r="7" cx="75" cy="50" />
    {pips.bottomLeft}
    {pips.bottomRight}
  </svg>
]

const FaceRotations = [
  'rotateY(-90deg)',
  'rotateX(-90deg)',
  'rotateY(90deg)',
  'rotateY(0deg)',
  'rotateX(90deg)',
  'rotateX(180deg)'
]

const DieRotations = [
  'rotateX(5deg) rotateY(-10deg) rotateY(90deg)',
  'rotateX(5deg) rotateY(-10deg) rotateX(90deg)',
  'rotateX(5deg) rotateY(-10deg) rotateY(-90deg)',
  'rotateX(5deg) rotateY(-10deg) rotateX(360deg)',
  'rotateX(5deg) rotateY(-10deg) rotateX(-90deg)',
  'rotateX(5deg) rotateY(-10deg) rotateX(-180deg)'
]

/**
 * Render a six-sided die.
 */
export default function Die( { animated = true, face = 6, sel, "class": className, ...props} ) {
  return <div className={["jk-die", animated && 'jk-die--animated', sel && 'jk-selected', className].filter(Boolean).join(" ")}
    aria-label={`Die showing ${face}`}
    role="img" {...props}
  >
    <div class="jk-shadow" aria-hidden={true} />
    <div class="jk-die-body"
      style={{ transform: DieRotations[face - 1] }}
      aria-hidden={true}
    >
      <div class="jk-internal" style={{ transform: 'rotateZ(0deg)' }}  />
      <div class="jk-internal" style={{ transform: 'rotateX(90deg)' }}  />
      <div class="jk-internal" style={{ transform: 'rotateY(90deg)' }}  />
      {(()=>{const results=[];let i = 0;for (const f of PipFaces) {
        const key = i++;
        results.push(<>
        <div class={["jk-face", key === face-1 ? 'jk-topface' : 'jk-sideface'].filter(Boolean).join(" ")}
            style={{ transform: `${FaceRotations[key]} translateZ(calc(var(--jk-die-size) * .5))`, }}
            key={"face"+key} > {f}
        </div>
        <div class="jk-inner-face" key={key} style={{ transform: `${FaceRotations[key]} translateZ(calc(var(--jk-die-size) * .49))`, }}  />
        </>)
      }return results})()
      }
    </div>
  </div>
}
