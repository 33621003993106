import React from 'react'
import Card from './Card'
import Dungeon from './Dungeon'
var cards = require('../bosses.json')

export default function Bossz() {
  return <div class="allcardz">
    {(()=>{const results=[];for (const key in cards) {
      const card = cards[key];
      results.push(<div class="dwrap" key={key}>
        <div class="cardzwrap">
          <Dungeon class="cardz" card={card} key={"d"+key} />
        </div>
        <div class="cardzwrap">
          <Card class="cardz" size={1} card={card} key={key} />
        </div>
      </div>)
    }return results})()
    }
  </div>
}
