import React from 'react'
import Card from './Card'
import Hero from './Hero'
import Dungeon from './Dungeon'
import Levels from './Levels'
import Dice from './Dice'
import { curboxes, emptyboxes, cancover, cangetheroic, canuseskill, checkResist } from '../Game'

export default function Board({ ctx, G, moves }) {
  return <div class="table">
    <div class="decks">
      <Dungeon class={("f"+G.floor) || ""} card={G.boss} size={1}
          effect={G.effect}
          cancover={(box) => true}
          rolling={ctx.phase === 'place'}
           />
      <Card {...( (()=>{let m;if(m = [ctx.phase, G.dungeon.every($ => ($ != null)), !!G.deck],Array.isArray(m) && m.length === 3 && m[0] === 'choice' && m[1] === false && m[2] === true) {
                const [,,] = m;
                return ({class: "deck activemove",
                onClick: () => moves.explore(),
                label: "Deck",
                size: G.deck})}
else if(Array.isArray(m) && m.length === 3 && m[0] === 'choice' && m[2] === false) {
                const [, ,] = m;
                return ({class: "deck activemove",
                onClick: () => moves.descend(),
                label: "Stairs 🪜 " + "💔".repeat(G.stairs),
                size: 0})}
else if(Array.isArray(m) && m.length === 3 && m[2] === false) {
                const [, ,] = m;
                return ({class: "deck",
                label: "Stairs 🪜 " + "💔".repeat(G.stairs),
                size: 0})}
else  {
                return ({class: "deck",
                label: "Deck",
                size: G.deck})
              }})()
            )}
       />
      <Card class="discard" label="Discard" size={G.discard.length} />
    </div>
    <div class="dungeons">
      {(()=>{const results=[];for (let i1 = 0; i1 < 4; ++i1) {
        const i = i1;
        results.push(<Card
          moves={moves}
          size={(G.dungeon[i] != null)}
          card={G.dungeon[i]}
          cancover={(box) => cancover(G, box)}
          dice={G.dice.filter($1 => $1.loc === 'box')}
          effect={G.effect}
          {...( (()=>{let m1;if(m1 = [G.dungeon[i] === null, i === G.room, ctx.phase],Array.isArray(m1) && m1.length === 3 && m1[0] === false && m1[2] === 'choice') {
              const [, ,] = m1;
              return ({class: "activemove",
              onClick: () => moves.enter(i)})}
else if(Array.isArray(m1) && m1.length === 3 && m1[1] === true && m1[2] === 'open') {
              const [ ,,] = m1;
              return ({encounter: true})}
else if(Array.isArray(m1) && m1.length === 3 && m1[1] === true && m1[2] === 'place') {
              const [ ,,] = m1;
              return ({rolling: true,
              warnend: !!emptyboxes(G).length,
              critend: emptyboxes(G).some($2 => $2.armor) && emptyboxes(G).length < curboxes(G).length})}
else if(Array.isArray(m1) && m1.length === 3 && m1[1] === true && m1[2] === 'loot') {
              const [ ,,] = m1;
              return ({looting: true,
              warnitems: G.items.length >= G.level.items,
              warnskills: G.skills.length >= G.level.skills})}
else  {
              return (G.dungeon[i] != null) &&
                {class: "inactive"}
            }})()
          )}
          key={i}
           />)
      }return results})()
      }
    </div>
    <div class="levels">
        <Levels level={G.level} xps={G.xps} potions={G.potions} ptokens={G.ptokens} cangetheroic={cangetheroic(G)} moves={moves} canuseskill={(ind) => canuseskill(G, ctx.phase, ind, true)} />
    </div>
    <div class="playarea">
        <Hero
            hero={G.hero} items={G.items} skills={G.skills} hp={G.hp} poison={G.poison} moves={moves} level={G.level}
            canuseskill={(ind) => canuseskill(G, ctx.phase, ind)}
            discard={ctx.phase === "discard" &&
                (G.items.length > G.level.items ? "items" : "skills")
            }
         />
        <Dice class={(ctx.phase === 'resist' && !checkResist(G) && "jk-warn") || ""}
            dice={G.dice}
            touch={ctx.phase === 'resist' ? moves.resist : moves.toggledie} />
    </div>
  </div>
}
