
import { rollOne, gain, tick, pooldice, taggeddice, discarddice, discardtagged } from './Game'


export const effects = {
  'ACID SPRAY': {
    'd': 'Place 💧 on a hero.',
    fn({ G, playerID, random, events }) {
      G.poison++
    }
  },
  'CHARGE': {
    'd': 'Dice may not be rerolled or increased.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'DARK AURA': {
    'd': 'Spend ⏳ for each 6 rolled.',
    postroll({ G, playerID, random, events }) {
      for (const d of pooldice(G)) {
        if (d.value === 6) {
          tick(G)
        }
      }
    }
  },
  'DODGE': {
    'd': 'Making a ✴️ die uses three dice, not two.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'DRAIN': {
    'd': 'Before the encounter, convert one item to XP.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'ETHEREAL': {
    'd': 'Immediately discard all 1s and 3s rolled.',
    postroll({ G, playerID, random, events }) {
      for (const d of pooldice(G)) {
        if (d.value === 1 || d.value === 3) {
          d.locopt = true
        }
      }
      discarddice(G)
    }
  },
  'FADE': {
    'd': 'Spend ⏳ for each skill you use.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'FLAMES': {
    'd': 'Before the encounter, place ❤️ on a hero.',
    fn({ G, playerID, random, events }) {
      G.hp += 1
    }
  },
  'FROST': {
    'd': 'Before the encounter,spend ⏳⏳⏳.',
    fn({ G, playerID, random, events }) {
      tick(G)
      tick(G)
      tick(G)
    }
  },
  'GAZE': {
    'd': 'Skip the Use Heroic Feats step.',
    fn({ G, playerID, random, events }) {
      events.setPhase('roll')
      return true
    }
  },
  'HOWL': {
    'd': 'You cannot place ✴️ on dungeon challenge boxes.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'PACK HUNTER': {
    'd': 'For each closed door, spend ⏳.',
    fn({ G, playerID, random, events }) {
      for (let i1 = 0; i1 < 4; ++i1) {
        const i = i1;
        if (G.doors[i] === false) {
          tick(G)
        }
      }
    }
  },
  'POISON SNAP': {
    'd': 'Convert one 💧 on a hero to ❤️.',
    fn({ G, playerID, random, events }) {
      if (G.poison > 0) {
        G.poison--
        G.hp++
      }
    }
  },
  'SPIKES': {
    'd': ' Suffer 💧 when you place a 5 on this card.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'SPLIT': {
    'd': 'Spend ⏳ for each 1 rolled.',
    postroll({ G, playerID, random, events }) {
      for (const d of pooldice(G)) {
        if (d.value === 1) {
          tick(G)
        }
      }
    }
  },
  'SURVIVOR': {
    'd': 'If any armor boxes are empty, discard this instead of looting.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'SWARM': {
    'd': 'X = 4 per open door, including this one.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'THICK ARMOR': {
    'd': 'Spend ⏳⏳ when you place a ✴️ on this card.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'UNDYING': {
    'd': 'If any boxes are empty, spend ⏳⏳.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'WEB': {
    'd': 'Spend ⏳⏳ for each 💧 in the party.',
    fn({ G, playerID, random, events }) {
      for (let end = G.poison, i2 = 0, asc = 0 <= end; asc ? i2 < end : i2 > end; asc ? ++i2 : --i2) {
        tick(G)
        tick(G)
      }
    }
  },
  'WOUND': {
    'd': 'Remove ✴️✴️ from play.',
    postroll({ G, playerID, random, events }) {
      let selected = 0
      for (const d of pooldice(G)) {
        if (d.dtype === 'heroic') {
          if (selected++ === 2) {
            break
          }
          d.locopt = true
        }
      }
      discarddice(G)
    }
  },
}

export const skills = {
  'ACCURACY': {
    'd': 'Gain a ✴️ 6.',
    fn({ G, playerID, random, events }) {
      gain(G, 'heroic', 6)
    }
  },
  'ADEPT': {
    'd': 'Roll 🗡️👢. Discard one of them.',
    fn({ G, playerID, random, events }) {
      rollOne(G, random, 'strength', true)
      rollOne(G, random, 'agility', true)
      G.mindice = G.maxdice = -1
      events.setPhase('skill')
    },
    then(G) {
      discardtagged(G)
    }
  },
  'AERA': {
    'd': 'Increase a non-✴️ die by 2.',
    fn({ G, playerID, random, events }) {
      for (const d of pooldice(G)) {
        if (d.dtype !== 'heroic') {
          d.locopt = true
        }
      }
      G.mindice = G.maxdice = 1
      events.setPhase('skill')
    },
    then(G) {
      for (const d of taggeddice(G)) {
        d.value = Math.min(6, d.value + 2)
      }
    }
  },
  'ARMOR': {
    'd': 'For every ❤️❤️ you would lose, prevent ❤️. You cannot prevent damage otherwise.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'ARMOR CRUSH': {
    'd': 'Gain a ✴️ 6. You can only use it to cover a box with 🛡️.',
    fn({ G, playerID, random, events }) {
      gain(G, 'heroic', 6)
      return
    }
  },
  'AWARENESS': {
    'd': 'Reduce the difficulty of each challenge box by 1.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'BACKSTAB': {
    'd': 'Roll 🗡️🗡️.',
    fn({ G, playerID, random, events }) {
      rollOne(G, random, 'strength')
      rollOne(G, random, 'strength')
    }
  },
  'BALANCED ATTACK': {
    'd': 'Roll 🗡️👢🌀. Increase one of them by 1.',
    fn({ G, playerID, random, events }) {
      rollOne(G, random, 'strength', true)
      rollOne(G, random, 'agility', true)
      rollOne(G, random, 'magic', true)
      G.mindice = G.maxdice = 1
      events.setPhase('skill')
    },
    then(G) {
      for (const d of taggeddice(G)) {
        d.value = Math.min(6, d.value + 1)
      }
    }
  },
  'BARRAGE': {
    'd': 'Roll 👢👢👢. Discard one of them.',
    fn({ G, playerID, random, events }) {
      rollOne(G, random, 'agility', true)
      rollOne(G, random, 'agility', true)
      rollOne(G, random, 'agility', true)
      G.mindice = G.maxdice = -1
      events.setPhase('skill')
    },
    then(G) {
      discardtagged(G)
    }
  },
  'BLITZ': {
    'd': 'Roll 🗡️. Increase a die by 1.',
    fn({ G, playerID, random, events }) {
      rollOne(G, random, 'strength')
      for (const d of pooldice(G)) {
        d.locopt = true
      }
      G.mindice = G.maxdice = 1
      events.setPhase('skill')
    },
    then(G) {
      for (const d of taggeddice(G)) {
        d.value = Math.min(6, d.value + 1)
      }
    }
  },
  'BRAWN': {
    'd': 'Roll 🗡️, and gain a 🗡️ 3.',
    fn({ G, playerID, random, events }) {
      rollOne(G, random, 'strength')
      gain(G, 'strength', 3)
    }
  },
  'BRUTE FORCE': {
    'd': 'Discard any number of value 5 dice. Gainthat many 🗡️ 6.',
    fn({ G, playerID, random, events }) {
      G.maxdice = 0
      for (const d of pooldice(G)) {
        if (d.value === 5) {
          d.locopt = true
          G.maxdice--
        }
      }
      events.setPhase('skill')
    },
    then(G) {
      const n = taggeddice(G).length
      discardtagged(G)
      for (let i3 = 0, asc1 = 0 <= n; asc1 ? i3 < n : i3 > n; asc1 ? ++i3 : --i3) {
        gain(G, 'strength', 6)
      }
    }
  },
  'CHAOTIC AURA': {
    'd': 'Pick a value. Change up to five of your dice of that value to 6s.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'CHARMED PANTHER': {
    'd': 'Gain 🗡️ 4 or 👢 4.',
    fn({ G, playerID, random, events }) {
      gain(G, 'strength', 4, true)
      gain(G, 'agility', 4, true)
      G.mindice = G.maxdice = -1
      events.setPhase('skill')
    },
    then(G) {
      discardtagged(G)
    }
  },
  'CLARITY': {
    'd': 'Reroll all your 1s and 2s. Roll ✴️.',
    fn({ G, playerID, random, events }) {
      rollOne(G, random, 'heroic')
      return
    }
  },
  'CLEAVE': {
    'd': 'Increase up to four of your dice by 1 each.',
    fn({ G, playerID, random, events }) {
      for (const d of pooldice(G)) {
        d.locopt = true
      }
      G.maxdice = 4
      events.setPhase('skill')
    },
    then(G) {
      for (const d of taggeddice(G)) {
        d.value = Math.min(6, d.value + 1)
      }
    }
  },
  'CLEVER SMASH': {
    'd': 'Roll ✴️. You may discard it to gain a 🗡️ 3.',
    fn({ G, playerID, random, events }) {
      rollOne(G, random, 'heroic', true)
      G.maxdice = -1
      events.setPhase('skill')
    },
    then(G) {
      if (taggeddice(G).length) {
        gain(G, 'strength', 3)
      }
      discardtagged(G)
    }
  },
  'CONSISTENCY': {
    'd': 'Change any or all of your dice to 4s.',
    fn({ G, playerID, random, events }) {
      G.maxdice = 0
      for (const d of pooldice(G)) {
        d.locopt = true
        G.maxdice++
      }
      events.setPhase('skill')
    },
    then(G) {
      for (const d of taggeddice(G)) {
        d.value = 4
      }
    }
  },
  'CRITICAL STRIKES': {
    'd': 'Change one or two 👢 dice into ✴️ of the same values.',
    fn({ G, playerID, random, events }) {
      for (const d of pooldice(G)) {
        if (d.dtype === 'agility') {
          d.locopt = true
        }
      }
      G.maxdice = 1
      G.maxdice = 2
      events.setPhase('skill')
    },
    then(G) {
      for (const d of taggeddice(G)) {
        gain(G, 'heroic', d.value)
      }
      discardtagged(G)
    }
  },
  'CRUSHING BLOW': {
    'd': 'Gain a 🗡️ 6.',
    fn({ G, playerID, random, events }) {
      gain(G, 'strength', 6)
    }
  },
  'CRUSHING FIST': {
    'd': 'Gain 🗡️ 6 🗡️ 6.',
    fn({ G, playerID, random, events }) {
      gain(G, 'strength', 6)
      gain(G, 'strength', 6)
    }
  },
  'DEVASTATE': {
    'd': 'Gain 🗡️ 5 👢 4 🌀 3.',
    fn({ G, playerID, random, events }) {
      gain(G, 'strength', 5)
      gain(G, 'agility', 4)
      gain(G, 'magic', 3)
    }
  },
  'DEXTERITY': {
    'd': 'Gain a 👢 6.',
    fn({ G, playerID, random, events }) {
      gain(G, 'agility', 6)
    }
  },
  'DODGE': {
    'd': 'Prevent ❤️. In a boss fight, prevent ❤️❤️.',
    fn({ G, playerID, random, events }) {
      G.prevent.health++
      return
    }
  },
  'ENCHANTED KRIS': {
    'd': 'For every 2 mana spent (up to 8), roll 👢 or roll 🗡️.',
    fn({ G, playerID, random, events }, cost) {
      return
    }
  },
  'EXPLOIT': {
    'd': 'Treat one challenge box as if it were any color.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'EXPLOIT WEAKNESS': {
    'd': 'Gain a ✴️ 4.',
    fn({ G, playerID, random, events }) {
      gain(G, 'heroic', 4)
    }
  },
  'FAERIE FIRE': {
    'd': 'Add 🗡️X and 👢X.',
    fn({ G, playerID, random, events }, cost) {
      gain(G, 'strength', Math.min(6, cost))
      gain(G, 'agility', Math.min(6, cost))
    }
  },
  'FEINT': {
    'd': 'Prevent 👢, and gain a ❤️ 6.',
    fn({ G, playerID, random, events }) {
      G.prevent.health++
      gain(G, 'agility', 6)
    }
  },
  'FINESSE': {
    'd': 'Roll 👢. Gain a 🗡️ 4.',
    fn({ G, playerID, random, events }) {
      rollOne(G, random, 'agility')
      gain(G, 'strength', 4)
    }
  },
  'FIREBALL': {
    'd': 'Gain 🌀 5 🗡️ 5.',
    fn({ G, playerID, random, events }) {
      gain(G, 'magic', 5)
      gain(G, 'strength', 5)
    }
  },
  'FLAMEWALK': {
    'd': 'Gain 🗡️ 5 👢 6 🌀 4.',
    fn({ G, playerID, random, events }) {
      gain(G, 'strength', 5)
      gain(G, 'agility', 6)
      gain(G, 'magic', 4)
    }
  },
  'FLAMEWEAVE': {
    'd': 'Gain a 🌀 5.',
    fn({ G, playerID, random, events }) {
      gain(G, 'magic', 5)
    }
  },
  'FLURRY': {
    'd': 'Roll 👢👢🗡️.',
    fn({ G, playerID, random, events }) {
      rollOne(G, random, 'agility')
      rollOne(G, random, 'agility')
      rollOne(G, random, 'strength')
    }
  },
  'FOCUSED PLAN': {
    'd': 'Increase a 🗡️,🌀, and 👢 each by 1.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'FOLLOWUP': {
    'd': 'Change two 🗡️ and/or ✴️ dice to be 6s.',
    fn({ G, playerID, random, events }) {
      for (const d of pooldice(G)) {
        if (d.dtype === 'strength' || d.dtype === 'heroic') {
          d.locopt = true
        }
      }
      G.maxdice = 2
      events.setPhase('skill')
    },
    then(G) {
      for (const d of taggeddice(G)) {
        d.value = 6
      }
    }
  },
  'FOREST BLESSING': {
    'd': 'Reroll a die. Gain a ✴️ 1.',
    fn({ G, playerID, random, events }) {
      for (const d of pooldice(G)) {
        d.locopt = true
      }
      G.mindice = G.maxdice = 1
      events.setPhase('skill')
    },
    then(G, random) {
      const dtype = taggeddice(G)[0].dtype
      discardtagged(G)
      rollOne(G, random, dtype)
      gain(G, 'heroic', 1)
    }
  },
  'HASTE': {
    'd': 'Roll 👢👢.',
    fn({ G, playerID, random, events }) {
      rollOne(G, random, 'agility')
      rollOne(G, random, 'agility')
    }
  },
  'HEROISM': {
    'd': 'Change two of your non-✴️ dice to be 6s.',
    fn({ G, playerID, random, events }) {
      for (const d of pooldice(G)) {
        if (d.dtype !== 'heroic') {
          d.locopt = true
        }
      }
      G.maxdice = 2
      events.setPhase('skill')
    },
    then(G) {
      for (const d of taggeddice(G)) {
        d.value = 6
      }
    }
  },
  'IMMUNITY': {
    'd': 'Ignore one ⏳ and one 💧.',
    fn({ G, playerID, random, events }) {
      G.prevent.time++
      G.prevent.poison++
    }
  },
  'INVISIBILITY': {
    'd': 'Spend ⏳⏳ before an encounter. Skip to the Claim Loot phase.',
    fn({ G, playerID, random, events }) {
      tick(G)
      tick(G)
      events.setPhase('loot')
    }
  },
  'INVULNERABILITY': {
    'd': 'Prevent ❤️❤️.',
    fn({ G, playerID, random, events }) {
      G.prevent.health++
    }
  },
  'KITING': {
    'd': 'If you would lose only one ❤️, spend ⏳ instead. Prevent one ❤️ in each boss\n      round.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'LILIA': {
    'd': 'Your 🌀 dice can be placed in any color boxes on the dungeon card.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'LUCK': {
    'd': 'Roll ✴️✴️, then discard one of them.',
    fn({ G, playerID, random, events }) {
      rollOne(G, random, 'heroic', true)
      rollOne(G, random, 'heroic', true)
      G.maxdice = -1
      events.setPhase('skill')
    },
    then(G) {
      discardtagged(G)
    }
  },
  'LUCKY FAMILIAR': {
    'd': 'Reroll one of your dice OR increase one of your dice by 1.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'MANA': {
    'd': 'Roll 🌀🌀🌀.',
    fn({ G, playerID, random, events }) {
      rollOne(G, random, 'magic')
      rollOne(G, random, 'magic')
      rollOne(G, random, 'magic')
    }
  },
  'MANA FOUNTAIN': {
    'd': 'Gain a 🌀 6.You can only use it as mana for a spell.',
    fn({ G, playerID, random, events }) {
      gain(G, 'magic', 6)
      return
    }
  },
  'METHODICAL': {
    'd': 'Prevent ❤️ and spend ⏳. In a boss fight, only prevent ❤️.',
    fn({ G, playerID, random, events }) {
      G.prevent.health++
      tick(G)
      return
    }
  },
  'PERSISTENCE': {
    'd': 'Roll 🗡️✴️.',
    fn({ G, playerID, random, events }) {
      rollOne(G, random, 'strength')
      rollOne(G, random, 'heroic')
    }
  },
  'PIERCING': {
    'd': 'Ignore all 🛡️ icons in this encounter.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'POISON': {
    'd': 'Prevent up to ⏳⏳.',
    fn({ G, playerID, random, events }) {
      G.prevent.time += 2
    }
  },
  'PRECISE BLOW': {
    'd': 'Gain 🗡️ 5 👢 2.',
    fn({ G, playerID, random, events }) {
      gain(G, 'strength', 5)
      gain(G, 'agility', 2)
    }
  },
  'PULVERIZE': {
    'd': 'Roll ✴️, then increase it by 1.',
    fn({ G, playerID, random, events }) {
      const d = rollOne(G, random, 'heroic')
      d.value = Math.min(6, d.value+1)
    }
  },
  'SECOND WIND': {
    'd': 'When you descend, heal two damage.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'SHIELD AURA': {
    'd': 'Prevent ❤️.',
    fn({ G, playerID, random, events }) {
      G.prevent.health++
    }
  },
  'SHIMMERBLAST': {
    'd': 'Roll ✴️.',
    fn({ G, playerID, random, events }) {
      rollOne(G, random, 'heroic')
    }
  },
  'SPARK': {
    'd': 'Roll 🌀, then increase it by 2.',
    fn({ G, playerID, random, events }) {
      const d = rollOne(G, random, 'magic')
      d.value = Math.min(6, d.value+2)
    }
  },
  'STATIC BURST': {
    'd': 'Gain 🗡️ 4 👢 4. Then, increase one of your dice by 1.',
    fn({ G, playerID, random, events }) {
      gain(G, 'strength', 4)
      gain(G, 'agility', 4)
      for (const d of pooldice(G)) {
        d.locopt = true
      }
      G.mindice = G.maxdice = 1
      events.setPhase('skill')
    },
    then(G) {
      for (const d of taggeddice(G)) {
        d.value = Math.min(6, d.value + 1)
      }
    }
  },
  'STEADY HANDS': {
    'd': 'Roll ✴️.',
    fn({ G, playerID, random, events }) {
      rollOne(G, random, 'heroic')
    }
  },
  'STEALTH': {
    'd': 'When you flee you may add one door to the dungeon, if under the door limit.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'STONESKIN': {
    'd': 'Prevent ❤️.',
    fn({ G, playerID, random, events }) {
      G.prevent.health++
    }
  },
  'SWIFTNESS AURA': {
    'd': 'Gain a 👢 3.',
    fn({ G, playerID, random, events }) {
      gain(G, 'agility', 3)
    }
  },
  'TRANSMUTE': {
    'd': 'Use another skill without paying for it.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
  'TRIPLE STRIKE': {
    'd': 'Gain 🗡️ 5,🌀 5, and 👢 5. Then, make one of them a 6.',
    fn({ G, playerID, random, events }) {
      gain(G, 'strength', 5, true)
      gain(G, 'magic', 5, true)
      gain(G, 'agility', 5, true)
      G.mindice = G.maxdice = 1
      events.setPhase('skill')
    },
    then(G) {
      for (const d of taggeddice(G)) {
        d.value = Math.min(6, d.value + 1)
      }
    }
  },
  'VALOR': {
    'd': 'Roll ✴️.',
    fn({ G, playerID, random, events }) {
      rollOne(G, random, 'heroic')
    }
  },
  'WHIRLWIND': {
    'd': 'Increase a 🗡️ and a 🌀 die by 2 each.',
    fn({ G, playerID, random, events }) {
      return
    }
  },
}
