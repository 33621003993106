import React from 'react'
import Card from './Card'
import Herocards from './Herocards'


export default function Levels( {xps, potions, ptokens, level, cangetheroic, moves, canuseskill, "class": className, ...props} ) {
  return <>
    <Card class="odd front combat" size={1}>
      <div class="carddata xps"> Level up: {level.levelup} 🔥
      </div>
      <div class="carddata cardimage"> {level.name}
      </div>
      <div class="carddata effects">
        <div class="boxes">
          <div class="box big"> <div class="hp"> Items
          </div>
          </div>
          <div class="box small"> <div class="hp"> {level.items}
          </div>
          </div>
          <div class="box big"> <div class="hp"> Skills
          </div>
          </div>
          <div class="box small"> <div class="hp"> {level.skills}
          </div>
          </div>
          <div class={["box small", cangetheroic && 'activemove'].filter(Boolean).join(" ")}
                onClick={() => cangetheroic && moves.getheroic() }>
            <div class="cons"> <div class="emoji"> ✴️
            </div>
            </div>
            <div class="hp"> {level.heroic}
            </div>
          </div>
          <div class="box small">
            <div class="cons"> <div class="emoji"> ⚗️
            </div>
            </div>
            <div class="hp"> {ptokens}
            </div>
          </div>
        </div>
      </div>
    </Card>

    <div class="herocards xpcards">
      <Herocards cards={xps}  />
    </div>
    <div class="herocards potions">
      <Herocards cards={potions} moves={moves} canuseskill={canuseskill} potion={true} />
    </div>
  </>
}
