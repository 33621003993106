import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Routes, Route, Outlet } from "react-router-dom";
import './index.css';
import App from './App';
import { Config } from './Config';
import Cardz from './components/Cardz';
import Heroz from './components/Heroz';
import Bossz from './components/Bossz';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route index element={<App />} />
          <Route path="config" element={<Config />} />
          <Route path="cardz" element={<Cardz />} />
          <Route path="heroz" element={<Heroz />} />
          <Route path="bossz" element={<Bossz />} />
        </Route>
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

// Reset all animations
const callback = () => { for (let a of document.getAnimations()) { if (a.animationName?.slice(0,3) !== "jk-") a.startTime = 0 } }
// Select the node that will be observed for mutations
const targetNode = document.getElementById('root');
// Options for the observer (which mutations to observe)
const config = { attributes: true, childList: true, subtree: true };
// Create an observer instance linked to the callback function
const observer = new MutationObserver(callback);
// Start observing the target node for configured mutations
observer.observe(targetNode, config);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
