import React from 'react'
import Die from './Die';


export default function Dice( {dice, touch, "class": className, ...props} ) {
  return <div className={["dicebox", className].filter(Boolean).join(" ")} {...props}>
    {(()=>{const results=[];for (const dtype of dice.map($ => $.dtype).filter((v, i, a) => a.indexOf(v) === i)) {
      results.push(<div class={["dicerow", dtype].filter(Boolean).join(" ")} key={dtype}>
        {(()=>{const results1=[];let i1 = 0;for (const d of dice) {
          const key = i1++;
          results1.push(<>
            {(d.dtype === dtype && d.loc === 'pool'?(
              <Die class={[dtype, d.locopt === 1 && 'jk-good', d.locopt === -1 && 'jk-warn'].filter(Boolean).join(" ")} key={key} animated={d.animated} face={d.value} sel={d.locopt} onClick={() => touch(key)} />
            ):void 0)
            }
          </>)
        }return results1})()
        }
      </div>)
    }return results})()
    }
  </div>
}
